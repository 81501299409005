// router.post("/user/register", users.register);
// router.post("/user/login", users.login);
// router.post("/user/me",auth, users.me);
// router.post("/user/logout", auth, users.logout);
// router.post("/user/create_payments_wildcore", auth, users.create_payments_wildcore);
// router.post("/user/get_payments_wildcore", auth, users.get_payments_wildcore);
// router.post("/user/approve_payments_wildcore", auth, users.approve_payments_wildcore);
// router.post("/user/create_payments_withdraw", auth, users.create_payments_withdraw);
// router.post("/user/get_payments_withdraw", auth, users.get_payments_withdraw);
// router.post("/user/approve_payments_withdraw", auth, users.approve_payments_withdraw);
// router.post("/user/get_users", auth, users.get_users);
// router.post("/user/get_server", auth, users.get_server);
// router.post("/user/register_ctv", auth, users.register_ctv);
import { httpClient as request } from "../../utils/Api";
export const register = payload => {
  return request.post("/user/register", payload);
};
export const login = payload => {
  return request.post("/user/login", payload)
};
export const me = payload => {
  return request.post("/user/me", payload);
};
export const logout = () => {
  return request.post("/user/logout");
};
export const create_payments_wildcore = (payload) => {
  return request.post("/user/create_payments_wildcore", payload);
};
export const get_payments_wildcore = (payload) => {
  return request.post("/user/get_payments_wildcore", payload);
};
export const approve_payments_wildcore = (payload) => {
  return payload.request_type == 9 ? request.post("/user/update_payments_wildcore", payload) : request.post("/user/approve_payments_wildcore", payload);
};
export const delete_payments_wildcore = (payload) => {
  return request.post("/user/delete_payments_wildcore", payload);
};
export const create_payments_withdraw = (payload) => {
  return request.post("/user/create_payments_withdraw", payload);
};
export const get_payments_withdraw = (payload) => {
  return request.post("/user/get_payments_withdraw", payload);
};
export const approve_payments_withdraw = (payload) => {
  return payload.request_type == 9 ? request.post("/user/update_payments_withdraw", payload) : request.post("/user/approve_payments_withdraw", payload);
};
export const delete_payments_withdraw = (payload) => {
  return request.post("/user/delete_payments_withdraw", payload);
};
export const get_users = (payload) => {
  return request.post("/user/get_users", payload);
};
export const register_ctv = (payload) => {
  return request.post("/user/register_ctv", payload);
};
export const update_user = (payload) => {
  return request.post("/user/update_user", payload);
};
export const change_password = payload => {
  return request.post("/user/change_password", payload);
};