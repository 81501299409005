import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "./constants";
import * as services from "./services";
// router.post("/user/register", users.register);
// router.post("/user/login", users.login);
// router.post("/user/me",auth, users.me);
// router.post("/user/logout", auth, users.logout);
// router.post("/user/create_payments_wildcore", auth, users.create_payments_wildcore);
// router.post("/user/get_payments_wildcore", auth, users.get_payments_wildcore);
// router.post("/user/approve_payments_wildcore", auth, users.approve_payments_wildcore);
// router.post("/user/create_payments_withdraw", auth, users.create_payments_withdraw);
// router.post("/user/get_payments_withdraw", auth, users.get_payments_withdraw);
// router.post("/user/approve_payments_withdraw", auth, users.approve_payments_withdraw);
// router.post("/user/get_users", auth, users.get_users);
// router.post("/user/get_server", auth, users.get_server);
// router.post("/user/register_ctv", auth, users.register_ctv);

export function* register(action) {
  try {
    const { data, status } = yield call(services.register, action.payload);
    yield put({
      type: types.REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.REGISTER_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.REGISTER_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* login(action) {
  try {
    const { data, status } = yield call(services.login, action.payload);
    if (status == 200 || status == 201) {
      yield put({
        type: types.LOGIN_SUCCESS,
        payload: data,
      });
    } else {
      
      yield put({
        type: types.LOGIN_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
        },
      });
    }
  } catch (error) {
    //console.log("error at get Clients", error);
    yield put({
      type: types.LOGIN_ERROR,
      payload: {
        msg: `Không thể kết nối server: ${error.message}`,
      },
    });
  }
}
export function* me(action) {
  try {
    const { data, status } = yield call(services.me, action.payload);
    if (status == 200 || status == 201) {
      yield put({
        type: types.ME_SUCCESS,
        payload: data,
      });
    } else {
      
      yield put({
        type: types.ME_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
        },
      });
    }
  } catch (error) {
    //console.log("error at get Clients", error);
    yield put({
      type: types.ME_ERROR,
      payload: {
        msg: `Không thể kết nối server: ${error.message}`,
      },
    });
  }
}
export function* logout(action) {
  try {
    const { data } = yield call(services.logout);
    yield put({
      type: types.LOG_OUT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    
    yield put({
      type: types.LOG_OUT_ERROR,
      payload: {
        msg: `Không thể kết nối server`,
        status: false
      },
    });
  }
}
export function* create_payments_wildcore(action) {
  try {
    const { data, status } = yield call(services.create_payments_wildcore, action.payload);
    yield put({
      type: types.CREATE_PAYMENT_WILDCORE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.CREATE_PAYMENT_WILDCORE_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.CREATE_PAYMENT_WILDCORE_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* get_payments_wildcore(action) {
  try {
    const { data, status } = yield call(services.get_payments_wildcore, action.payload);
    yield put({
      type: types.GET_PAYMENT_WILDCORE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.GET_PAYMENT_WILDCORE_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.GET_PAYMENT_WILDCORE_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* approve_payments_wildcore(action) {
  try {
    const { data, status } = yield call(services.approve_payments_wildcore, action.payload);
    yield put({
      type: types.APPROVE_PAYMENT_WILDCORE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.APPROVE_PAYMENT_WILDCORE_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.APPROVE_PAYMENT_WILDCORE_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* delete_payments_wildcore(action) {
  try {
    const { data, status } = yield call(services.delete_payments_wildcore, action.payload);
    yield put({
      type: types.DELETE_PAYMENT_WILDCORE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.DELETE_PAYMENT_WILDCORE_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.DELETE_PAYMENT_WILDCORE_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* create_payments_withdraw(action) {
  try {
    const { data, status } = yield call(services.create_payments_withdraw, action.payload);
    yield put({
      type: types.CREATE_PAYMENT_WITHDRAW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.CREATE_PAYMENT_WITHDRAW_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.CREATE_PAYMENT_WITHDRAW_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* get_payments_withdraw(action) {
  try {
    const { data, status } = yield call(services.get_payments_withdraw, action.payload);
    yield put({
      type: types.GET_PAYMENT_WITHDRAW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.GET_PAYMENT_WITHDRAW_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.GET_PAYMENT_WITHDRAW_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* approve_payments_withdraw(action) {
  try {
    const { data, status } = yield call(services.approve_payments_withdraw, action.payload);
    yield put({
      type: types.APPROVE_PAYMENT_WITHDRAW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.APPROVE_PAYMENT_WITHDRAW_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.APPROVE_PAYMENT_WITHDRAW_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* delete_payments_withdraw(action) {
  try {
    const { data, status } = yield call(services.delete_payments_withdraw, action.payload);
    yield put({
      type: types.DELETE_PAYMENT_WITHDRAW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.DELETE_PAYMENT_WITHDRAW_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.DELETE_PAYMENT_WITHDRAW_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* get_users(action) {
  try {
    const { data, status } = yield call(services.get_users, action.payload);
    yield put({
      type: types.GET_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.GET_USER_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.GET_USER_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* update_user(action) {
  try {
    const { data, status } = yield call(services.update_user, action.payload);
    yield put({
      type: types.UPDATE_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.UPDATE_USER_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.UPDATE_USER_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* register_ctv(action) {
  try {
    const { data, status } = yield call(services.register_ctv, action.payload);
    yield put({
      type: types.REGISTER_CTV_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.REGISTER_CTV_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.REGISTER_CTV_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* change_password(action) {
  try {
    const { data, status } = yield call(services.change_password, action.payload);
    yield put({
      type: types.CHANGE_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.CHANGE_PASSWORD_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.CHANGE_PASSWORD_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export default function* userSaga() {
  yield takeLatest(types.REGISTER, register);
  yield takeLatest(types.LOGIN, login);
  yield takeLatest(types.ME, me);
  yield takeLatest(types.LOG_OUT, logout);
  yield takeLatest(types.CREATE_PAYMENT_WILDCORE, create_payments_wildcore);
  yield takeLatest(types.GET_PAYMENT_WILDCORE, get_payments_wildcore);
  yield takeLatest(types.APPROVE_PAYMENT_WILDCORE, approve_payments_wildcore);
  yield takeLatest(types.DELETE_PAYMENT_WILDCORE, delete_payments_wildcore);

  yield takeLatest(types.CREATE_PAYMENT_WITHDRAW, create_payments_withdraw);
  yield takeLatest(types.GET_PAYMENT_WITHDRAW, get_payments_withdraw);
  yield takeLatest(types.APPROVE_PAYMENT_WITHDRAW, approve_payments_withdraw);
  yield takeLatest(types.DELETE_PAYMENT_WITHDRAW, delete_payments_withdraw);

  yield takeLatest(types.GET_USER, get_users);
  yield takeLatest(types.UPDATE_USER, update_user);
  yield takeLatest(types.REGISTER_CTV, register_ctv);
  yield takeLatest(types.CHANGE_PASSWORD, change_password);

}
