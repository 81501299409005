import { all } from "redux-saga/effects";
import accountSaga from "./modules/account/saga";
import settingSaga from "./modules/setting/saga";
import userSaga from "./modules/user/saga";


/**
 * Root saga
 * @returns {IterableIterator<AllEffect | GenericAllEffect<any> | *>}
 */
export default function* rootSagas() {
  yield all([
    userSaga(),
    
    accountSaga(),
   
    settingSaga()
  ]);
}
