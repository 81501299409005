import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "./constants";
import * as services from "./services";


export function* add_account(action) {
  try {
    const { data, status } = yield call(services.add_account, action.payload);
    yield put({
      type: types.ADD_ACCOUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.ADD_ACCOUNT_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.ADD_ACCOUNT_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* update_account(action) {
  try {

    const { data, status } = yield call(services.update_account, action.payload);
    yield put({
      type: types.UPDATE_ACCOUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.UPDATE_ACCOUNT_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.UPDATE_ACCOUNT_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* delete_account(action) {
  try {
    const { data, status } = yield call(services.delete_account, action.payload);
    yield put({
      type: types.DELETE_ACCOUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.DELETE_ACCOUNT_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.DELETE_ACCOUNT_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* get_accounts(action) {
  try {
    const { data, status } = yield call(services.get_accounts, action.payload);
    yield put({
      type: types.GET_ACCOUNT_BY_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.GET_ACCOUNT_BY_CATEGORY_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.GET_ACCOUNT_BY_CATEGORY_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export function* send_token(action) {
  try {
    const { data, status } = yield call(services.send_token, action.payload);
    yield put({
      type: types.SEND_TOKEN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response) {
      const { data, status } = error.response
      if (status == 401)
        yield put({
          type: "user/LOG_OUT_SUCCESS",
          payload: {
            msg: data.msg,
            status: false
          },
        });
      else yield put({
        type: types.SEND_TOKEN_ERROR,
        payload: {msg:"Không thể kết nối server", status:false},
      });
    }
    else
      yield put({
        type: types.SEND_TOKEN_ERROR,
        payload: {
          msg: `Không thể kết nối server`,
          status: false
        },
      });
  }
}
export default function* accountSaga() {
  yield takeLatest(types.ADD_ACCOUNT, add_account);
  yield takeLatest(types.UPDATE_ACCOUNT, update_account);
  yield takeLatest(types.DELETE_ACCOUNT, delete_account);
  yield takeLatest(types.GET_ACCOUNT_BY_CATEGORY, get_accounts);
  yield takeLatest(types.SEND_TOKEN, send_token);
}
