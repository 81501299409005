// router.post("/setting/set", auth, settings.setting_set);
// router.post("/setting/get", settings.setting_get);
// router.post("/setting/upload",  auth, upload.array("files", 1), medias.upload);

export const SETTING_SET = "setting/SETTING_SET";
export const SETTING_SET_SUCCESS = "setting/SETTING_SET_SUCCESS";
export const SETTING_SET_ERROR = "setting/SETTING_SET_ERROR";

export const SETTING_GET = "setting/SETTING_GET";
export const SETTING_GET_SUCCESS = "setting/SETTING_GET_SUCCESS";
export const SETTING_GET_ERROR = "setting/SETTING_GET_ERROR";

export const SETTING_SUMMARYS = "setting/SETTING_SUMMARYS";
export const SETTING_SUMMARYS_SUCCESS = "setting/SETTING_SUMMARYS_SUCCESS";
export const SETTING_SUMMARYS_ERROR = "setting/SETTING_SUMMARYS_ERROR";

export const RESET_SETTING = "setting/RESET_SETTING";
export const RESET_SETTING_SUCCESS = "setting/RESET_SETTING_SUCCESS";
export const RESET_SETTING_ERROR = "setting/RESET_SETTING_ERROR";


