import { Button, Checkbox, Input, message, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { KeyOutlined, LoginOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";
import { login, reset_user } from "../modules/user/actions";
const { Content } = Layout
const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const _user = useSelector(({ user }) => user.user);
  const _me = useSelector(({ user }) => user.me);

  const initialState = {
    email: localStorage.getItem("tcstore_email") ? localStorage.getItem("tcstore_email") : "",
    password: localStorage.getItem("tcstore_password") ? localStorage.getItem("tcstore_password") : "",
    remember: true
  }
  const [state, setState] = useState(initialState)
  const loginUser = () => {
    if (state.remember) {
      localStorage.setItem("tcstore_email", state.email)
      localStorage.setItem("tcstore_password", state.password)
    }
    else {
      localStorage.removeItem("tcstore_email")
      localStorage.removeItem("tcstore_password")
    }
    if (!state.email || !state.password)
      return message.warning("Vui lòng nhập đầy đủ thông tin đăng nhập")
    dispatch(login({
      device: "web",
      email: state.email,
      password: state.password
    }))
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      loginUser()
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)

    if (_me?.status == true) {
      history.push("/")
    }
    return () => {
      dispatch(reset_user({ remove: ["register", "user"] }))
    }
  }, [])
  return (

    <Layout  style={{ height:"100vh"}}>

      <Content
        style={{
         
          background: "#03A9F4",
        }}
      >


        <div className="center" style={{ width: isMobile ? "85%" : null, borderRadius:10 }}>
          <div >
            <div style={{ textAlign: "center", padding: 10 }}>
              <img width="200" src="https://images.viblo.asia/a87852d0-d60c-4a7c-ae42-0bfb6679ecb3.gif" />
              <h3>Đăng Nhập Hệ Thống</h3>
            </div>
            <Input placeholder="Device" style={{ display: "none" }} />
            <Input prefix={<UserOutlined />} style={{ marginBottom: "20px" }} placeholder="Tài khoản / Email / Phone / " value={state.email} onChange={(e) => setState({ ...state, email: e.target.value })} />
            <Input.Password prefix={<KeyOutlined />} style={{ marginBottom: "20px" }} type="password" placeholder="Mật khẩu" value={state.password}
              onChange={(e) => setState({ ...state, password: e.target.value })}
              onKeyDown={(e) => handleKeyDown(e)}
            />
            <Checkbox checked={state.remember} onChange={(e) => setState({ ...state, remember: e.target.checked })} style={{ marginBottom: "20px" }}>Ghi nhớ thông tin đăng nhập</Checkbox><br />
          </div>
          <div style={{ width: "100%", paddingBottom: 10 }}>
            <Button icon={<LoginOutlined />} style={{ width: "100%", margin: 0}} type="primary" onClick={() => loginUser()} loading={_user.isLoading}
              className="color1" htmlType="submit">
              Đăng Nhập
            </Button>
          
          </div>

        </div>
      </Content>

    </Layout>


  );
};

export default SignIn;
