import * as Types from "./constants";

const initState = {
  settingList: {
    isLoading: false,
    status: false,
    msg: null,
    data: []
  },
  settingSet: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  summarys: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  },
  pending: {
    isLoading: false,
    status: false,
    msg: null,
    data: {}
  }
};
export default function settingReducer(state = initState, action = {}) {
  switch (action.type) {
    case Types.SETTING_SET:
      return {
        ...state,
        settingSet: {
          ...state.buyBoost,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.SETTING_SET_SUCCESS:
      if(action.payload.status == true)
        state.settingList.data = action.payload.data

      return {
        ...state,
        settingList:{
          ...state.settingList,
          data: state.settingList.data
        },
        settingSet: {
          ...state.settingSet,
          ...action.payload,
          isLoading: false,
        }
      };
    case Types.SETTING_SET_ERROR:
      return {
        ...state,
        settingSet: {
          ...state.settingSet,
          ...action.payload,
          isLoading: false,
          status: false
        }
      };
    /////////
    case Types.SETTING_GET:
      return {
        ...state,
        settingList: {
          ...state.settingList,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.SETTING_GET_SUCCESS:
      return {
        ...state,
        settingList: {
          ...state.settingList,
          data: action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.SETTING_GET_ERROR:
      return {
        ...state,
        settingList: {
          ...state.settingList,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
    /////////
 
    case Types.SETTING_SUMMARYS:
      return {
        ...state,
        summarys: {
          ...state.summarys,
          isLoading: true,
          status: false,
          msg: null,
        }
      };
    case Types.SETTING_SUMMARYS_SUCCESS:
      return {
        ...state,
        summarys: {
          ...state.summarys,
          data: action.payload.data,
          isLoading: false,
          status: action.payload.status,
          msg: action.payload.msg
        }
      };
    case Types.SETTING_SUMMARYS_ERROR:
      return {
        ...state,
        summarys: {
          ...state.summarys,
          status: false,
          isLoading: false,
          msg: action.payload.msg
        }
      };
      ///////////
    case Types.RESET_SETTING:
      let newState = {}
      if (action.payload.remove[0] == "all")
        newState = initState
      else
        for (let [key, value] of Object.entries(state)) {
          if (action.payload.remove.includes(key))
            newState[key] = { ...initState[key] }
          else newState[key] = value
        };
      return newState

    default:
      return state;
  }
}
