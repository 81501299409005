import { httpClient as request } from "../../utils/Api";
// router.post("/setting/set", auth, settings.setting_set);
// router.post("/setting/get", settings.setting_get);
// router.post("/setting/upload",  auth, upload.array("files", 1), medias.upload);
export const setting_set = payload => {
  return  request.post("/setting/set", payload)
};

export const setting_get = payload => {
  return  request.post("/setting/get", payload)
};

export const setting_summarys = payload => {
  return request.post("/account/summarys", payload)
};



