import { CheckCircleOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Card, Input, message, Pagination, Select, Space, Table, Tag, Tooltip } from "antd";
import 'moment/locale/vi';
import { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from 'use-debounce';
import { get_users, reset_user, update_user } from "../../../modules/user/actions";
import { curencyFormat } from "../../../utils/help";
import UserView from "./UserView";

const { Option } = Select;
const { TextArea } = Input;

const Users = (props) => {
   const dispatch = useDispatch();
   const _userList = useSelector(({ user }) => user.userList);
   const _user = useSelector(({ user }) => user.me.user);
   const _updateUser = useSelector(({ user }) => user.updateUser);

   let initialState = {
      search: null,
      page: 0,
      role: null,
      note: null,
      userSelect: null,
      userNote: null,
      isVisibleUser: false
   }
   const [state, setState] = useState(initialState)
   const [search] = useDebounce(state.search, 1000);

   const handleGetUser = async () => {
      dispatch(get_users({
         search: state.search,
         role: state.role,
         page: state.page
      }))
   }
   useEffect(() => {
      if (search !== null)
         handleGetUser()
   }, [search])
   useEffect(() => {
      if (state.role !== null)
         handleGetUser()
   }, [state.role])

   useEffect(() => {
      window.scrollTo(0, 0)
      handleGetUser()
      return () => {
         dispatch(reset_user({ remove: ["userList", "updateUser"] }))
      };
     
   }, [])
   const handleUpdateNote = async ()=>{
      if (state.note !== null && state.userNote && state.note !== state.userNote.note )
      dispatch(update_user({
       ...state.userNote,
       note: state.note
      }))
   }
   const handleShowInfoUser = (record) => {
      setState({ ...state, userSelect: record, isVisibleUser: true })
   }
   const handleUpdateUser = () => {
      dispatch(update_user({...state.userSelect}))
   }
   
   useEffect(() => {
      if (_updateUser.status) message.success(_updateUser.msg)
      else if (!_updateUser.status && _updateUser.msg !== null) message.warning(_updateUser.msg)
   }, [_updateUser])
   let columns = [
      {
         title: '#',
         key: 'index',
         width: 50,
         align: "center",
         render: (text, record, index) => (_userList.current_page * _userList.per_page) + index + 1,
      },

      {
         title: 'Nguời dùng',
         dataIndex: 'name',
         key: 'name',
         width: 300,
         ellipsis: true,
         render: (text, record) => {
            return <>
               <Space>
                  <Avatar size="default" src={"/assets/images/user.png"} />
                  <div style={{ alignItems: "center" }}>
                     <span>
                        {record.name}
                     </span>
                     <br />
                     <span>
                        {record.email}
                     </span>
                  </div>
               </Space>
            </>
         }
      },
      {
         title: 'Phân quyền',
         dataIndex: 'role',
         key: 'role',
         width: 100,
         ellipsis: true,
         align: "center",
         render: (text) => <Tag style={{ borderRadius: 10 }} color={text == 0 ? "red" : text == 1 ? "orange" : "green"}>{text == 0 ? "ADMIN" : text == 1 ? "MANAGER" : "USER"}</Tag>
      },
      
      {
         title: "Ghi chú",
         key: 'note',
         dataIndex: 'note',
         width: 300,
         render: (text, record) => <div style={{display:"flex", flexDirection:"row"}}>
         <TextArea  style={{height:"60px"}}
         onClick={()=>{
            setState({...state, note: record.note, userNote: record})
         }} 
         onChange={(e)=>{
            setState({...state, note: e.target.value})
         }}
         rows={2}  value={state.userNote && state.userNote._id == record._id ? state.note : text} ></TextArea>
         <Button onClick={()=>handleUpdateNote()}  style={{height:"60px", width:"35px", marginLeft:5}} className="green" icon={<CheckCircleOutlined />}></Button>
         </div>
      },
      {
         title: 'Trạng thái',
         key: 'status',
         dataIndex: 'status',
         width: 150,
         align: "center",

         render: (text) => text ? <Badge color={"green"} text="Active" /> : <Badge color={"red"} text="Banned" />
      },
      {
         title: 'Menu',
         width: 60,
         align: "center",
         // fixed: 'right',
         render: (text, record) => (
            <Tooltip placement="bottom" title={"Chỉnh sửa user"}>
               <Button
                  type="primary"
                  size="small"
                  icon={<EditOutlined />}
                  className='ant-btn gx-btn-success'
                  onClick={() => {
                       handleShowInfoUser(record)
                  }}
               ></Button>
            </Tooltip>
         ),
      },
   ];
   let columns_mobile = [
      {
         title: '#',
         key: 'index',
         width: 30,
         align: "center",
         render: (text, record, index) => (_userList.current_page * _userList.per_page) + index + 1,
      },
      {
         title: 'Nguời dùng',
         dataIndex: 'name',
         key: 'name',
         width: 150,
         ellipsis: true,
         render: (text, record) => {
            return <>
               <Space>
                  <Avatar size="default" src={"https://i.pinimg.com/originals/e2/7c/87/e27c8735da98ec6ccdcf12e258b26475.png"} />
                  <div style={{ alignItems: "center" }}>
                     <span>
                        {record.username}
                     </span>
                     <br />
                     <span>
                        {record.phone}
                     </span>
                  </div>
               </Space>
            </>
         }
      },
      {
         title: 'Menu',
         width: 60,
         align: "center",
         // fixed: 'right',
         render: (text, record) => (
            <Tooltip placement="bottom" title={"Chỉnh sửa user"}>
               <Button
                  type="primary"
                  size="small"
                  icon={<EditOutlined />}
                  className='ant-btn gx-btn-success'
                  onClick={() => {
                       handleShowInfoUser(record)
                  }}
               ></Button>
            </Tooltip>
         ),
      },
   ];
   const optionTable = {
      bordered: true,
      loading: _userList.isLoading,
      size: 'small',
      showHeader: true,
      columns: isMobile ? columns_mobile:  columns,
      dataSource: [..._userList.data],
      pagination: { position: ['none', 'none'], defaultPageSize: _userList.per_page },
      scroll:  isMobile ? { } : { y: "calc(100vh - 300px)", x: 1000 }
   };

   const changePage = (page) => {
      setState({ ...state, page: page })
      dispatch(get_users({
         search: state.search,
         page: page - 1,
      }))
   }
   return (

      <Card
         bodyStyle={{ padding: 10 }}
         headStyle={{ padding: 10 }}
         size="small"
         title="Người dùng" 
         extra={
              <div>
                <Input type="search"
                  style={{ width: isMobile ? "100%": "300px",  }}
                  placeholder="Tìm theo tên / email"
                  onChange={(e) => setState({ ...state, search: e.target.value })}
                  value={state.search}
               ></Input>
               <Select
               defaultValue={state.role}
               value={state.role}
               style={{ width: isMobile ? "100%": "150px",  marginLeft: !isMobile ? 10:0, marginTop:10}} 
               onChange={(value) => {
                  setState({ ...state, role: value  })
               }}
            >
               <Option value={null}>Lọc theo Role</Option>
               <Option value={0}>Admin</Option>
               <Option value={1}>Manager</Option>
               <Option value={2}>User</Option>
            </Select>
              </div>
          
         }
         style={{ width: "100%" }}>

         <Table
            {...optionTable}
            rowKey={(record) => record._id}
         />
         <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
            <Pagination current={_userList.current_page + 1} total={_userList.total} pageSize={_userList.per_page} onChange={changePage} style={{ paddingTop: 10 }} showSizeChanger={false} />
         </Space>
         {
            state.userSelect ?  <UserView _updateUser={_updateUser } isMobile={isMobile} state={state} _user={_user} setState={setState} handleUpdateUser={handleUpdateUser}/>: null
         }

      </Card>


   )
}
export default Users