
import {
    DollarCircleOutlined, FireOutlined, FundViewOutlined, HistoryOutlined, SkinOutlined, TrophyOutlined, UserOutlined, ShoppingOutlined, AppstoreOutlined,
    SketchOutlined, QuestionCircleOutlined, LogoutOutlined, MenuUnfoldOutlined, MenuFoldOutlined, VideoCameraOutlined, SettingOutlined
} from '@ant-design/icons';
import { Col, Menu, message, Row, Layout, Space, Button, Popover, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import { isMobile, BrowserView, MobileView,} from 'react-device-detect';

import { Link } from "react-router-dom";
import Router from "./Router";
import { useDispatch, useSelector } from "react-redux";

import { logout } from '../../modules/user/actions';
import { httpClient } from '../../utils/Api';
import { curencyFormat } from '../../utils/help';
import Mobile_Menu from '../../components/Mobile_Menu'
import Settings from './Settings'

const { Header, Sider, Content } = Layout;


const App = (props) => {
    const dispatch = useDispatch()
    const { match, history, location } = props

    let defaultUrl = "/application/victims"
    const [current, setCurrent] = useState(defaultUrl);
    const _me = useSelector(({ user }) => user.me);
    const user = _me.user
    const initialState = {
        menuOpen: false,
        contact: null,
        link: null,
        isVisibleSetting: false
    }
    const [state, setState] = useState(initialState)
    const [collapsed, setCollapsed] = useState(isMobile);
    const items = [

      
        {
            label:
                <Link to="/application/summarys">
                    <span style={{ color: isMobile ? "white" : "white", fontSize: 14, marginLeft: 8 }}>Dashboard</span>
                </Link>,
            key: '/application/summarys',
            icon: <FireOutlined />,
        },
        {
            label: <Link to="/application/users">
                <span style={{ color: isMobile ? "white" : "white", fontSize: 14, marginLeft: 8 }}>Người dùng</span>
            </Link>,
            key: '/application/users',
            icon: <UserOutlined />,
        },
        {
            label: <Link to="/application/victims">
                <span style={{ color: isMobile ? "white" : "white", fontSize: 14, marginLeft: 8 }}>Victim</span>
            </Link>,
            key: '/application/victims',
            icon: <TrophyOutlined />,
        },
        {
            label:
                <Link to="/application/me">
                    <span style={{ color: isMobile ? "white" : "white", fontSize: 14, marginLeft: 8 }}>Me</span>
                </Link>,
            key: '/application/me',
            icon: <FundViewOutlined />,
        },
        {
            label: <Link to="#" onClick={() => {
                setState({ ...state, isVisibleSetting: true })
            }} >
                <span style={{ color: isMobile ? "white" : "white", fontSize: 14, marginLeft: 8 }}>Cài đặt</span>
            </Link>,
            key: '/application/settings',
            icon: <SettingOutlined />,

        },
    ];
    const onLogoutClick = () => {
        dispatch(logout())
        localStorage.removeItem('tcstore_token');
        httpClient.defaults.headers.common['Authorization'] = '';
        history.push('/signin');
    }
    useEffect(() => {

        if (location.pathname == "/") {
            setCurrent(defaultUrl)
            history.push(defaultUrl)
        }
        else {
            setCurrent(location.pathname)
        }
    }, [location.pathname])

    useEffect(() => {

        if (!user?._id || (user && user?.status == false && user?.msg !== null)) {
            if (user?._id !== undefined) message.warning("Vui lòng đăng nhập")
            history.push("/signin")
        }
    }, [user])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const Menu_Content = (props) => {
        return (
            <div>
                {
                    !isMobile ?
                        user && user?.email ? <>
                            <Popover placement="bottom" content={
                                <Space>
                                    <Link to="/application/me">
                                        <Button type='dashed' icon={<UserOutlined />} size="small" style={{ color: "black", fontSize: 14, margin: "0 4px" }}>

                                            <span style={{ color: "black", fontSize: 14, marginLeft: 8 }}>Tài khoản</span>

                                        </Button>
                                    </Link>
                                    <Button type="dashed" size='small' danger onClick={() => onLogoutClick()}>Đăng xuất</Button>
                                </Space>
                            } title={`Xin chào ${user?.name}!`}>
                                <Link to="/application/me" >

                                    <Button icon={<UserOutlined />} type="primary" size='small' style={{ borderRadius: 20, fontSize: 14, padding: "0 10px", margin: "0 4px" }}
                                        className="color1">
                                        <span style={{ color: "white", fontSize: 14 }}>
                                            <span >Hi {user.email.split("@")[0]}</span>

                                        </span>


                                    </Button>
                                </Link>
                            </Popover>

                        </> :
                            <>
                                <Link to="/signin">
                                    <Button icon={<UserOutlined />} type="primary" size='small' style={{ color: "white", fontSize: 14, padding: "0 10px", margin: "0 4px" }} className="color1">

                                        <span style={{ color: "white", fontSize: 14, marginLeft: 8 }}>Đăng Nhập</span>

                                    </Button>
                                </Link>
                                <Link to="/signup">
                                    <Button icon={<UserOutlined />} type="dashed" size='small' style={{ color: "white", fontSize: 14, padding: "0 10px" }} className='color2'>

                                        <span style={{ fontSize: 14, marginLeft: 8, color: "white" }} >Đăng Ký</span>

                                    </Button>
                                </Link>
                            </> : null
                }

                <MobileView>
                    {
                        user && user?.email ?

                            <>
                                <Link to="/profile/">
                                    <Button icon={<UserOutlined />} type="primary" size='small' style={{ fontSize: 14, width: "100%", borderRadius: 5, height: 40, marginBottom: 10, border: "none" }} className="color1_" >

                                        <span style={{ color: "white", fontSize: 14 }}>
                                            <span >{user.email.toUpperCase()}</span>
                                            <span style={{ marginLeft: 5, marginRight: 5 }}> - </span>
                                            <span>{curencyFormat(user.balance)}</span>
                                        </span>


                                    </Button>
                                </Link>
                                <Button icon={<LogoutOutlined />} type="dashed" size='small' danger onClick={() => onLogoutClick()} style={{ fontSize: 14, width: "100%", borderRadius: 5, height: 40, marginBottom: 10, border: "none" }} className="color2_" >ĐĂNG XUẤT</Button>
                            </>
                            :
                            <>
                                <Link to="/signin">
                                    <Button type="primary" size='small' style={{ fontSize: 14, width: "100%", borderRadius: 5, height: 40, marginBottom: 10, border: "none" }} className="color1_" >

                                        <span style={{ color: "white", fontSize: 14, marginLeft: 8 }}>ĐĂNG NHẬP</span>


                                    </Button>
                                </Link>

                            </>
                    }
                </MobileView>
            </div>
        )
    }

    return (
        <Layout style={{
            minHeight: '100vh',
        }}>
            <Sider trigger={null} collapsible collapsed={collapsed} width={250} onCollapse={(value) => setCollapsed(value)}  style={{ 
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        padding:10
    }}>
                <div style={{ position: "relative", height: "100%" }}>
                    <div style={{ position: "absolute", top: 10, width: "100%", textAlign: "center" }}>
                        {!collapsed ? <>

                            <img style={{ cursor: "pointer"}} onClick={() => history.push("/")} height={75} width={75} src="https://cdn-icons-png.flaticon.com/512/8605/8605622.png" /><br />
                            <p style={{ color: "white", }}>Hi {user?.role == 0 ? "Admin" : user?.role == 1 ? "Manager" : "User"}!</p>
                    
                            <Popover style={{ marginTop: 10 }} placement="bottom" content={

                                <Button type="dashed" size='small' danger onClick={() => onLogoutClick()}>Đăng xuất</Button>

                            }>
                                <Button className="color2" type="dashed" style={{borderRadius:5}} onClick={() => onLogoutClick()}>{user?.name}</Button>
                            </Popover>

                        </> : <Popover style={{ marginTop: 10 }} placement="bottom" content={

                            <Button  icon={<LogoutOutlined/>} size='small' danger onClick={() => onLogoutClick()}></Button>

                        }> <img style={{ cursor: "pointer",}} onClick={() => history.push("/")} height={50} width={50} src="https://cdn-icons-png.flaticon.com/512/8605/8605622.png" />
                        </Popover>}
                        <div style={{borderTop:"2px solid #222c35", height:30, marginTop:20}}></div>
                    </div>

                    <div style={{ position: "absolute", bottom: 60, width: "100%" }}>
                        {/* <div style={{borderTop:"2px solid #222c35", height:30}}></div> */}
                        <Menu
                            selectedKeys={[current]}
                            mode="inline"
                            inlineCollapsed={isMobile}
                            style={{

                                // height:"auto",
                                // background:"white"
                            }}
                            defaultOpenKeys={["0", "1"]}
                            className="menu_cpanel"
                            theme='dark'

                            items={user?.role !== 0 ? items.filter(item=>item.key !== "/application/settings" && item.key !== "/application/users" && item.key !== "/application/summarys") : items}
                        />
                    </div>
                    <div style={{ position: "absolute", bottom: 0, width: "100%", background:"#252f45" , color:"white", textAlign:"center", padding:15}}>
                    {isMobile ? null :
                            React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                className: 'trigger',
                                onClick: () => setCollapsed(!collapsed),
                            })
                        }
                    </div>

                </div>

            </Sider>
            <Layout className="site-layout"
         
            >

             
                <Content
          
         
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        background: "white",
                        overflow: 'auto',
                        marginLeft: isMobile || collapsed ? "100px" : '250px'
                    }}
                >
                    <div style={{ background: "white", padding: 10, width: "100%", height: "100%" }}>
                        <Router match={match} location={location} history={history} />
                    </div>
                </Content>
                <Settings state={state} setState={setState} />
            </Layout>
        </Layout>



    )

};

export default App;
