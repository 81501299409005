import { CalculatorOutlined, CalendarOutlined, GlobalOutlined, KeyOutlined, LogoutOutlined, MailOutlined, PhoneOutlined, SendOutlined, TagOutlined, UserOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Collapse, Input, Row, Select, Space } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import 'moment/locale/vi';
import React from "react";
import { NumericInput } from "../../../utils/help";
const { Option } = Select;

const UserView = (props) => {
   let state = props.state
   let isMobile = props.isMobile
   let _updateUser = props._updateUser
   let userSelect = state.userSelect
   return (
      <Modal title={`Chỉnh sửa`}
         visible={state.isVisibleUser}
         style={{ top: 20 }}
         onCancel={() => props.setState({ ...state, isVisibleUser: false })}
         width={isMobile ? "90%" : "40%"}
         footer={<Space direction="horizontal" style={{ width: '100%', justifyContent: isMobile ? "center" : "right" }} className="gx-w-100">
            <Button
               loading={_updateUser.isLoading}
               className='color1'
               onClick={() => {
                  props.handleUpdateUser()
               }} type="primary" icon={<SendOutlined />}>Cập nhật</Button>
            <Button icon={<LogoutOutlined />} onClick={() => props.setState({ ...state, isVisibleUser: false })} type="primary" className='color2'>Thoát</Button>
         </Space>}
      >
         <Row >

            {!isMobile ? <Col xl={12} lg={12} md={24} sm={24} xs={24} className={!isMobile ? "pdR" : "pdB"}>
               <Input size="middle" placeholder="Username" prefix={<UserOutlined />}
                  value={userSelect.email}
                  addonAfter="Username"
                  disabled
               />
            </Col> : null}
            <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <Input size="middle" placeholder="Tên người dùng" prefix={<UserOutlined />}
                  value={userSelect.name}
                  addonAfter="Tên"
                  onChange={(e) => props.setState({ ...state, userSelect: { ...userSelect, name: e.target.value } })}
               />
            </Col>
         </Row>
         {!isMobile ?
            <Row >

               <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                  <Input size="middle" placeholder="Ngày đăng ký" prefix={<CalendarOutlined />}
                     value={moment(userSelect.createdAt).format("DD/MM/YYYY HH:mm")}
                     disabled
                     addonAfter="Ngày đăng ký"
                  />
               </Col>
               <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                  <Input size="middle" placeholder="Cập nhật lúc" prefix={<CalendarOutlined />}
                     value={moment(userSelect.updatedAt).format("DD/MM/YYYY HH:mm")}
                     addonAfter="Ngày cập nhật"
                     disabled />
               </Col>

            </Row> : null}
        
         <Row >
            {!isMobile ? <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <Input size="middle" placeholder="Địa chỉ IP" prefix={<GlobalOutlined />}
                  value={userSelect?.ip_web}
                  addonAfter="IP"
                  disabled
                  maxLength={10}
               />

            </Col> : null}
            <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <Input.Password size="middle" placeholder="Để trống nếu không đổi" prefix={<KeyOutlined />}
                  value={userSelect.password}
                  addonAfter="Password"
         
                  onChange={(e) => props.setState({ ...state, userSelect: { ...userSelect, password: e.target.value } })}
               />
            </Col>
         </Row>
         <Row >
            <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <p>Phân quyền</p>
               <Select
                  addonAfter="Profit"
                  defaultValue={userSelect.role}
                  value={userSelect.role}
                  style={{ width: "100%", }} onChange={(value) => {
                     props.setState({ ...state, userSelect: { ...userSelect, role: value,  } })
                  }}
               >
                  <Option value={0}>Admin</Option>
                  <Option value={1}>Manager</Option>
                  <Option value={2}>User</Option>
               </Select>
            </Col>
          
            <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
               <p>Trạng thái hoạt động</p>
               <Select
                  defaultValue={userSelect.status}
                  value={userSelect.status}
                  style={{ width: "100%", }} onChange={(value) => props.setState({ ...state, userSelect: { ...userSelect, status: value } })}
               >
                  <Option value={false}><Badge color={"red"} size="20" style={{ marginRight: 10 }}></Badge>Banned</Option>
                  <Option value={true}><Badge color={"green"} size="20" style={{ marginRight: 10 }}></Badge>Active</Option>

               </Select>
            </Col>
         </Row>
      </Modal>

   )
}
export default UserView