import axios from 'axios';

export const httpClient = axios.create({
  baseURL:  `${process.env.REACT_APP_API_URL}/`,
  headers: {
    'Content-Type': 'application/json',
    'Accept' : 'application/json',
  },
  timeout: 1000 * 60
});
