import Nprogress from "nprogress";
import "nprogress/nprogress.css";
import React, { Component } from "react";
import ReactPlaceholder from "react-placeholder";

import "react-placeholder/lib/reactPlaceholder.css";
import CircularProgress from "../components/CircularProgress";

export default function asyncComponent(importComponent) {
  class AsyncFunc extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null
      };
    }

    componentWillMount() {
      Nprogress.start();
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    async componentDidMount() {
      this.mounted = true;
      const {default: Component} = await importComponent();
      // setTimeout(() => {
        Nprogress.done();
        if (this.mounted) {
          this.setState({
            component: <Component {...this.props} />
          });
        }
      // }, 5000000);
     
    }

    render() {
      const Component = this.state.component || <CircularProgress className={"centerText"}/>;
      return (
        <ReactPlaceholder type="text" rows={20} ready={Component !== null}>
          {Component}
        </ReactPlaceholder>
      );
    }
  }

  return AsyncFunc;
}
