
import { Input } from 'antd';
export const k = '38c11cb6f86a902501e5a34f451adb75'
export const uuidv4Short = () => {
    return "4xxx-yxxx-xxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
export const formatMoney = (number, lamtron) => {

    return number = (Math.round(number * 100) / 100);
}
export const isVietnamesePhoneNumberValid = (number) => {
    return /(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})\b/.test(number);
}
export const randomNumber= (min, max) =>{ // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  

export const NumericInput = (props) => {
    const { value, onChange } = props;

    const handleChange = (e) => {

        const reg = /^-?\d*(\.\d*)?$/;

        if (reg.test(e.target.value) || e.target.value === '') {
            onChange(e);
        }
        else {
            e.target.value= e.target.value.replace(/\D/g, '');
            onChange(e);
        }
    }; 
    return (

        <Input
            {...props}
            onChange={handleChange}
        />
    );
};

export const curencyFormat = (value) => {
    if(value)
        return Number(parseInt(Math.round( value * 100) / 100)).toLocaleString() + " $"
    else {
      return "0 $"
    }
};
export const numberFormat = (value) => {
    if(value)
        return (Math.round( value * 100) / 100).toLocaleString()
    else {
      return "0"
    }
};
export const get_rank_name = (rank) => {
	rank = rank.split("_")
	let result = ""
	switch (rank[0]) {
		case "iron":
			result = "Sắt";
			break;
		case "bronze":
			result = "Đồng";
			break;
		case "silver":
			result = "Bạc";
			break;
		case "gold":
			result = "Vàng";
			break;
		case "platinum":
			result = "Bạch kim";
			break;
		case "emerald":
			result = "Lục bảo";
			break;
		case "diamon":
			result = "Kim cương";
			break;
		case "master":
			result = "Cao thủ";
			break;
		case "grandmaster":
			result = "Đại cao thủ";
			break;
		default: result = "Thách đấu"
	}
	if(rank[1]) result = `${result} ${rank[1]}`
	return result

}
export const localeDate =  {
    lang: {
      "locale": "vi_VN",
      placeholder: "Chọn một ngày",
      rangePlaceholder: [
        "Ngày bắt đầu",
        "Ngày cuối"
      ],
      today: 'Hôm nay',
      now: 'Bây giờ',
      backToToday: 'Quay lại hôm nay',
      ok: 'OK',
      clear: 'Xóa',
      month: 'Tháng',
      year: 'Năm',
      timeSelect: 'Chọn thời gian',
      dateSelect: 'Chọn ngày',
      monthSelect: 'Chọn tháng',
      yearSelect: 'Chọn năm',
      decadeSelect: 'Chọn thập kỷ',
      previousMonth: 'Tháng trước',
      nextMonth: 'Tháng tới',
      previousYear: 'Năm ngoái',
      nextYear: 'Năm sau',
      previousDecade: 'Thập kỉ trước',
      nextDecade: 'Thập kỉ tiếp theo',
      previousCentury: 'Thế kỉ trước',
      nextCentury: 'Thế kỉ tiếp theo',
      "yearFormat": "YYYY",
      "dateFormat": "M/D/YYYY",
      "dayFormat": "D",
      "dateTimeFormat": "M/D/YYYY HH:mm:ss",
      "monthFormat": "MMMM",
      monthBeforeYear: true
    },
    "timePickerLocale": {
      "placeholder": "Select time"
    },
    "dateFormat": "YYYY-MM-DD",
    "dateTimeFormat": "YYYY-MM-DD HH:mm:ss",
    "weekFormat": "YYYY-wo",
    "monthFormat": "YYYY-MM"
  }
  let firstname = ["NGUYEN", "TRAN", "LE", "PHAM", "HOANG", "HUYNH", "PHAN", "VU", "VO", "DANG", "BUI", "DO", "HO", "NGO", "DUONG", "LY", "Elisabeth", "Jodie", "Marshanika", "Latarsha", "Lashanta", "Eucebio", "Sinh", "Mooky", "Devante", "Zahid", "Magdalena", "Karne"]
  let lastName = ["BACH", "BAO", "BANG", "BICH", "BINH", "CANH", "CHIEN", "CHINH", "CHUNG", "CHUNG", "CONG", "CUNG", "CUONG", "DANH", "DIEM", "DIEP", "DIEU", "DOANH", "DUNG", "DUNG", "DUY", "DUYEN", "DUONG", "DAI", "DAO", "DAN", "DANG", "DANG", "DANG", "DICH", "DOAN", "GIANG", "HAU", "HIEN", "HIEN", "HIEN", "HIEN", "HIEP", "HIEU", "HINH", "HOA", "HOA", "HOACH", "HOAI", "HOAN", "HOAN", "HOANG", "HUAN", "HUAN", "HUNG", "HUY", "HUYEN", "HUYNH", "HUYNH", "HUA", "HUNG", "HUONG", "HUU", "KIM", "KIEU", "KIET", "KHA", "KHANG", "KHAI", "KHAI", "KHANH", "KHOA", "KHOI", "KHUAT", "KHUE", "KY", "PHUONG", "PHUNG", "PHUNG", "PHUONG", "QUANG", "QUACH", "QUAN", "QUOC", "QUYEN", "QUYNH", "SANG", "SAM", "THANH", "THANH", "THANH", "THANH", "THANH", "THAO", "THAO", "THANG", "THUAN", "THUY", "THUY", "THUY", "THUY", "THUY", "THU", "THU", "THUONG", "Katheryn", "Jabari", "Rikki", "Hermine", "Dillon", "Garmai", "Aubery", "Rocio", "Natahn", "Mirko", "Celia", "Demtrics", "Della", "Evin", "Aitor", "Monde", "Power", "Dude", "Pamala", "Lasandra", "Nikki", "Arther", "Khalid"]
  export let winLabel = ["1 nick thử vận may 50k", "1 nick thử vận may 100k", "1 nick thử vận may 200k", "1 nick ngon", "1 nick vip", "1 nick giá rẻ", "110 Wildcore", "1130 Wildcore", "555 Wildcore", "2895 Wildcore", "275 Wildcore", "6025 Wildcore"]
  export const genUsername = ()=>{
    var sub_name; var first_name; var last_name;
    first_name = firstname[Math.floor(Math.random() * firstname.length)];
    sub_name = lastName[Math.floor(Math.random() * lastName.length )]
    last_name = lastName[Math.floor(Math.random() * lastName.length)]
    return  (first_name+sub_name+last_name).toLowerCase()
}