import { KeyOutlined, LoginOutlined, MailOutlined, PhoneOutlined, UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { register, reset_user } from "../modules/user/actions";
const SignUp = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const _user = useSelector(({ user }) => user.user);
  const _register = useSelector(({ user }) => user.register);
  const initialState = {
    username: "",
    password: "",
    name: "",
    email: "",
    phone: "",
    remember: false
  }
  const [state, setState] = useState(initialState)
  const handleRegister = () => {
    if (!state.username || !state.password || !state.email || !state.name)
      return message.warning("Vui lòng nhập đầy đủ thông tin đăng ký")
    dispatch(register({
      device: "web",
      username: state.username,
      password: state.password,
      email: state.email,
      name: state.name,
      phone: state.phone
    }))
  };
  useEffect(() => {
    if (!_register.status && _register.msg !== null) message.warning(_register.msg)
  }, [_register])

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleRegister()
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
      dispatch(reset_user({ remove: ["register"] }))
    }
  }, [])
  return (

    <div className="center" style={{width: isMobile ? "85%": null}}>
      <div >
        <div style={{ textAlign: "center", padding: 10 }}>
          <img width="200" src="https://images.viblo.asia/a87852d0-d60c-4a7c-ae42-0bfb6679ecb3.gif" />
          <h3>Đăng Ký Tài Khoản</h3>
        </div>
        <Input style={{ marginBottom: 20 }} prefix={<UserOutlined />} placeholder="Tài khoản" value={state.username} onChange={(e) => setState({ ...state, username: e.target.value })} />
        <Input.Password style={{ marginBottom: 20 }} prefix={<KeyOutlined />} type="password" placeholder="Mật khẩu" value={state.password} onChange={(e) => setState({ ...state, password: e.target.value })} />
        <Input style={{ marginBottom: 20 }} prefix={<UserAddOutlined />} placeholder="Họ tên của bạn" value={state.name} onChange={(e) => setState({ ...state, name: e.target.value })} />
        <Input style={{ marginBottom: 20 }} prefix={<MailOutlined />} placeholder="Email của bạn" value={state.email}
          onKeyDown={(e) => handleKeyDown(e)}
          onChange={(e) => setState({ ...state, email: e.target.value })} />
        <Input style={{ marginBottom: 20 }} prefix={<PhoneOutlined />} placeholder="SĐT của bạn" value={state.phone}
          onKeyDown={(e) => handleKeyDown(e)}
          onChange={(e) => setState({ ...state, phone: e.target.value })} />
      </div>
      <div style={{ width: "100%", paddingBottom: 10 }}>
        <Button icon={<UserAddOutlined />} style={{ width: "49%", margin: 0, marginRight: "2%" }} type="primary" onClick={() => handleRegister()} loading={_register.isLoading} className="color1" >
          Đăng Ký
        </Button>
        <Button icon={<LoginOutlined />} style={{ width: "49%", margin: 0 }} type="primary" onClick={() => history.push('/signin')} className="color2" htmlType="submit">
          Về Đăng Nhập
        </Button>
      </div>
    </div>

  );
};

export default SignUp
