import React from "react";
import { Route, Switch } from "react-router-dom";


import Application from "./Application";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

const App = ({ match}) => {

  return (
    <Switch>
      <Route exact path='/signin' component={SignIn} />
      <Route exact path='/signup' component={SignUp} />
      <Route path={`${match.url}`}  component={Application}  />
 

    </Switch>

  );
}



export default App;
