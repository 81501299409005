import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "../../utils/asyncComponent";
import Users from "./Users";
const App = ({ match, history, location }) => {

    return (
        <Switch>
          

            <Route path={`/application/users`}  exact component={Users} />
            <Route path={`/application/victims`}   component={asyncComponent(() => import('./Victims'))} />
            <Route path={`/application/me`}   component={asyncComponent(() => import('./Me'))} />
            <Route path={`/application/summarys`}   component={asyncComponent(() => import('./Summarys'))} />
            <Route path={`/application/`}   component={asyncComponent(() => import('./Victims'))} />

        </Switch>

    );
}



export default App;
