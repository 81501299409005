import { DeleteOutlined, LogoutOutlined, PlusOutlined, QuestionCircleOutlined, SaveOutlined, KeyOutlined } from "@ant-design/icons";
import { Badge, Button, Checkbox, Col, Divider, Input, message, Modal, Popconfirm, Row, Select, Space, Tabs, Tooltip } from "antd";
import 'moment/locale/vi';
import { useEffect, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from 'use-debounce';
import { curencyFormat, NumericInput, uuidv4Short } from "../../../utils/help";

import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import RichEditor from "../../../components/RichEditor";
import Tags from "../../../components/Tags";
import { setting_get, setting_set } from "../../../modules/setting/actions";

const { Option } = Select;
const { TextArea } = Input;
const Accounts_History = (props) => {
   let { isVisibleSetting } = props.state
   const dispatch = useDispatch();
   const _settingList = useSelector(({ setting }) => setting.settingList);
   const _settingSet = useSelector(({ setting }) => setting.settingSet);

   let initialState = {
      isVisibleConfirmDelete: false,
      config_telegram: {},
      wallet: {
         wallet_list: [],

      },
      wallet_transfer: {
         wallet_list: [],

      },
      keyTab: "1"
   }
   const [state, setState] = useState(initialState)

   useEffect(() => {
      if (isVisibleSetting == true)
         dispatch(setting_get({
            request_type: 0
         }))

   }, [isVisibleSetting])

   useEffect(() => {
     try {
      if (_settingList.status) {
         let wallet = _settingList.data.length && _settingList.data.find(item => item.key == "wallet")
         let config_telegram = _settingList.data.length && _settingList.data.find(item => item.key == "config_telegram")
         let wallet_transfer = _settingList.data.length && _settingList.data.find(item => item.key == "wallet_transfer")
         setState({
            ...state,

            wallet: { ...wallet.value, index: _settingList.data.indexOf(wallet) },
            wallet_transfer: { ...wallet_transfer.value, index: _settingList.data.indexOf(wallet_transfer) },
            config_telegram: { list: config_telegram?.value, description: config_telegram?.description, index: _settingList.data.indexOf(config_telegram) },


         })
      }
      else if (_settingList.status == false && _settingList.msg !== null) {
         message.warning("Không tìm thấy cấu hình server")
      }
     } catch (error) {
      
     }
   }, [_settingList])

   useEffect(() => {
      if (_settingSet.status) {
         message.success(_settingSet.msg)
      }
      else if (_settingSet.status == false && _settingSet.msg !== null) {
         message.warning(_settingSet.msg)
      }
   }, [_settingSet])



   const handleSave = () => {

      if (state.wallet?.wallet_list?.length == 0 || typeof state.wallet?.wallet_list == "undefined")
         return message.warning("Vui lòng thêm ví approve")
      let wallet_result = null
      state.wallet?.wallet_list.map((list, index) => {
         if (!list.trc || !list.erc || !list.id || !list.erc_key || !list.trc_key || ![true, false].includes(list.status)) {
            wallet_result = index
         }
      })
      if (wallet_result !== null) return message.warning(`Ví approve số ${wallet_result + 1} đang cấu hình sai`)
      ///////////////
      if (state.wallet_transfer?.wallet_list?.length == 0 || typeof state.wallet_transfer?.wallet_list == "undefined")
         return message.warning("Vui lòng thêm ví transfer")
      let wallet_transfer_result = null
      state.wallet_transfer?.wallet_list.map((list, index) => {
         if (!list.trc || !list.erc || !list.id || ![true, false].includes(list.status)) {
            wallet_result = index
         }
      })
      if (wallet_transfer_result !== null) return message.warning(`Ví transfer số ${wallet_transfer_result + 1} đang cấu hình sai`)
      ///////////////
      if (state.config_telegram?.list?.token == null || state.config_telegram?.list?.token == "" || state.config_telegram?.list?.token == undefined)
         return message.warning(`Setting telegram token đang cấu hình sai`)
      if (state.config_telegram?.list?.pass == null || state.config_telegram?.list?.pass == "" || state.config_telegram?.list?.pass == undefined)
         return message.warning(`Setting telegram token đang cấu hình sai`)

      ///////////////
      let newSettingList = _settingList.data
      newSettingList[state.wallet.index].value = state.wallet
      newSettingList[state.config_telegram.index].value = state.config_telegram.list

      dispatch(setting_set({
         settings: newSettingList
      }))
   }

   return (
      <Modal title={"Cài đặt"}
         visible={isVisibleSetting}
         style={{ top: 20 }}
         bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
         destroyOnClose={true}
         maskClosable={false}
         onCancel={() => props.setState({ ...state, isVisibleSetting: false })}
         width={isMobile ? "90%" : "900px"}
         footer={<Space direction="horizontal" style={{ width: '100%', justifyContent: isMobile ? "center" : "right" }} className="gx-w-100">
            <Button
               className='color1'
               loading={_settingSet.isLoading}
               onClick={() => {
                  handleSave()
               }}
               type="primary" icon={<SaveOutlined />}>{"Lưu cấu hình"}</Button>
            <Button icon={<LogoutOutlined />} onClick={() => props.setState({ ...state, isVisibleSetting: false })} type="primary" className='color2'>Thoát</Button>
         </Space>}
      >

         <Tabs defaultActiveKey="1" onChange={(value) => setState({ ...state, keyTab: value })}>

            <Tabs.TabPane tab="Chung" key="1">

               {state.config_telegram ?
                  <>
                     <Divider orientation="left" orientationMargin={0}>Cấu hình bot telegram</Divider>
                     <Row>
                        <Col xl={24} lg={24} md={24} sm={24} className={"pdB"}>
                           <Input prefix={
                              <Tooltip placement="bottom" title={state.config_telegram.description}>
                                 <QuestionCircleOutlined />
                              </Tooltip>

                           } addonAfter={<div style={{ width: 80 }}>Token bot</div>}
                              value={state.config_telegram?.list?.token}

                              onChange={(e) => setState({ ...state, config_telegram: { ...state.config_telegram, list: { ...state.config_telegram.list, token: e.target.value } } })}
                           />
                        </Col>
                     </Row>
                     <Row>
                        <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                           <Input prefix={<KeyOutlined />} addonAfter={<div style={{ width: 80 }}>Mật khẩu bot</div>}
                              value={state.config_telegram?.list?.pass}
                              onChange={(e) => setState({ ...state, config_telegram: { ...state.config_telegram, list: { ...state.config_telegram.list, pass: e.target.value } } })}
                           />
                        </Col>
                        <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                           <Input prefix={<KeyOutlined />} addonAfter={<div style={{ width: 80 }}>ChatId</div>}
                              disabled
                              value={state.config_telegram?.list?.chatId}
                              onChange={(e) => setState({ ...state, config_telegram: { ...state.config_telegram, list: { ...state.config_telegram.list, chatId: e.target.value } } })}
                           />
                        </Col>

                     </Row>
                     <Row>
                        <Col span={24}>
                           <p>Nhận thông báo</p>
                           <Checkbox.Group style={{ marginBottom: 10, width: "100%" }} value={state.config_telegram?.list?.enable}
                              onChange={(e) => setState({ ...state, config_telegram: { ...state.config_telegram, list: { ...state.config_telegram.list, enable: e } } })}

                           >

                              <Checkbox value="approve">Approve</Checkbox>
                              <Checkbox value="payout">Payout</Checkbox>


                           </Checkbox.Group>
                        </Col>
                     </Row>
                  </> : null
               }


            </Tabs.TabPane>

            <Tabs.TabPane tab="Wallet Approve" key="2">
               <Row>
                  <Col span={24}>
                     {state.wallet && state.wallet?.wallet_list?.length ?
                        state.wallet.wallet_list.map((item, index) => {
                           return (
                              <div >
                                 <Divider orientation="left" orientationMargin={0}>Ví {index + 1}</Divider>
                                 <Row>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                                       <Input value={item.trc} addonAfter={<div style={{ width: 80 }}>Ví TRC</div>}
                                          onChange={(e) => {
                                             let oldState = state.wallet
                                             oldState.wallet_list[index].trc = e.target.value
                                             let newState = oldState
                                             setState({ ...state, wallet: { ...newState } })
                                          }}
                                       />

                                    </Col>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                                       <Input value={item.trc_key} addonAfter={<div style={{ width: 80 }}>Key TRC</div>}
                                          onChange={(e) => {
                                             let oldState = state.wallet
                                             oldState.wallet_list[index].trc_key = e.target.value
                                             let newState = oldState
                                             setState({ ...state, wallet: { ...newState } })
                                          }}
                                       />

                                    </Col>
                                 </Row>
                                 <Row>

                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                                       <Input value={item.erc} addonAfter={<div style={{ width: 80 }}>Ví ERC/BSC</div>}
                                          onChange={(e) => {
                                             let oldState = state.wallet
                                             oldState.wallet_list[index].erc = e.target.value
                                             let newState = oldState
                                             setState({ ...state, wallet: { ...newState } })
                                          }}
                                       />

                                    </Col>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                                       <Input value={item.erc_key} addonAfter={<div style={{ width: 80 }}>Key ERC</div>}
                                          onChange={(e) => {
                                             let oldState = state.wallet
                                             oldState.wallet_list[index].erc_key = e.target.value
                                             let newState = oldState
                                             setState({ ...state, wallet: { ...newState } })
                                          }}
                                       />

                                    </Col>
                                 </Row>

                                 <Row>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                                       <Input value={item.id} addonAfter={<div style={{ width: 80 }}>ID</div>}
                                          onChange={(e) => {
                                             let oldState = state.wallet
                                             oldState.wallet_list[index].id = e.target.value
                                             let newState = oldState
                                             setState({ ...state, wallet: { ...newState } })
                                          }}
                                       />
                                    </Col>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                                       <Select
                                          placeholder="Trạng thái"
                                          value={item.status}
                                          style={{ width: "100%" }}
                                          onChange={(value) => {
                                             let oldState = state.wallet
                                             oldState.wallet_list[index].status = value
                                             let newState = oldState
                                             setState({ ...state, wallet: { ...newState } })
                                          }}
                                       >
                                          <Option value={true}><Badge color={"green"} size="20" text="Kích hoạt"></Badge></Option>
                                          <Option value={false}><Badge color={"red"} size="20" text="Ngừng hoạt động"></Badge></Option>

                                       </Select>

                                    </Col>
                                 </Row>


                              </div>
                           )
                        }) : null
                     }
                  </Col>
                  <Col span={24} style={{ marginTop: 10 }}>
                     <Button icon={<PlusOutlined />} onClick={() => {
                        setState({ ...state, wallet: { ...state.wallet, wallet_list: [...state.wallet.wallet_list, { ...{ id: uuidv4Short(), status: true, trc: null, erc: null, trc_key: null, erc_key: null } }] } })
                     }} type="dashed" style={{ width: "100%" }}>Thêm ví Approve</Button>
                     {/* <Button icon={<SaveOutlined />} onClick={handleAddMoreList} type="primary" style={{ width: "100%", marginTop: 10 }}>Lưu cấu hình</Button> */}
                  </Col>
               </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Wallet Transfer To" key="3">
               <Row>
                  <Col span={24}>
                     {state.wallet_transfer && state.wallet_transfer?.wallet_list?.length ?
                        state.wallet_transfer.wallet_list.map((item, index) => {
                           return (
                              <div >
                                 <Divider orientation="left" orientationMargin={0}>Ví {index + 1}</Divider>
                                 <Row>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                                       <Input value={item.trc} addonAfter={<div style={{ width: 80 }}>Ví TRC</div>}
                                          onChange={(e) => {
                                             let oldState = state.wallet_transfer
                                             oldState.wallet_list[index].trc = e.target.value
                                             let newState = oldState
                                             setState({ ...state, wallet_transfer: { ...newState } })
                                          }}
                                       />

                                    </Col>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                                       <Input value={item.erc} addonAfter={<div style={{ width: 80 }}>Ví ERC/BSC</div>}
                                          onChange={(e) => {
                                             let oldState = state.wallet_transfer
                                             oldState.wallet_list[index].erc = e.target.value
                                             let newState = oldState
                                             setState({ ...state, wallet_transfer: { ...newState } })
                                          }}
                                       />

                                    </Col>
                                 </Row>
                                

                                 <Row>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdR" : "pdB"}>
                                       <Input value={item.id} addonAfter={<div style={{ width: 80 }}>ID</div>}
                                          onChange={(e) => {
                                             let oldState = state.wallet_transfer
                                             oldState.wallet_list[index].id = e.target.value
                                             let newState = oldState
                                             setState({ ...state, wallet_transfer: { ...newState } })
                                          }}
                                       />
                                    </Col>
                                    <Col xl={12} lg={12} md={24} sm={24} className={!isMobile ? "pdL" : "pdB"}>
                                       <Select
                                          placeholder="Trạng thái"
                                          value={item.status}
                                          style={{ width: "100%" }}
                                          onChange={(value) => {
                                             let oldState = state.wallet_transfer
                                             oldState.wallet_list[index].status = value
                                             let newState = oldState
                                             setState({ ...state, wallet_transfer: { ...newState } })
                                          }}
                                       >
                                          <Option value={true}><Badge color={"green"} size="20" text="Kích hoạt"></Badge></Option>
                                          <Option value={false}><Badge color={"red"} size="20" text="Ngừng hoạt động"></Badge></Option>

                                       </Select>

                                    </Col>
                                 </Row>


                              </div>
                           )
                        }) : null
                     }
                  </Col>
                  <Col span={24} style={{ marginTop: 10 }}>
                     <Button icon={<PlusOutlined />} onClick={() => {
                        setState({ ...state, wallet_transfer: { ...state.wallet_transfer, wallet_list: [...state.wallet_transfer.wallet_list, { ...{ id: uuidv4Short(), status: true, trc: null, erc: null} }] } })
                     }} type="dashed" style={{ width: "100%" }}>Thêm ví Tranfer</Button>
                     {/* <Button icon={<SaveOutlined />} onClick={handleAddMoreList} type="primary" style={{ width: "100%", marginTop: 10 }}>Lưu cấu hình</Button> */}
                  </Col>
               </Row>
            </Tabs.TabPane>
         </Tabs>
      </Modal>


   )
}
export default Accounts_History