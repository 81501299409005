// router.post("/user/register", users.register);
// router.post("/user/login", users.login);
// router.post("/user/me",auth, users.me);
// router.post("/user/logout", auth, users.logout);
// router.post("/user/create_payments_wildcore", auth, users.create_payments_wildcore);
// router.post("/user/get_payments_wildcore", auth, users.get_payments_wildcore);
// router.post("/user/approve_payments_wildcore", auth, users.approve_payments_wildcore);
// router.post("/user/create_payments_withdraw", auth, users.create_payments_withdraw);
// router.post("/user/get_payments_withdraw", auth, users.get_payments_withdraw);
// router.post("/user/approve_payments_withdraw", auth, users.approve_payments_withdraw);
// router.post("/user/get_users", auth, users.get_users);
// router.post("/user/get_server", auth, users.get_server);
// router.post("/user/register_ctv", auth, users.register_ctv);

import * as types from "./constants";
export function register(payload) {
  return {
    type: types.REGISTER,
    payload,
  };
}
export function login(payload) {
  return {
    type: types.LOGIN,
    payload,
  };
}
export function me(payload) {
  return {
    type: types.ME,
    payload,
  };
}
export function logout() {
  return {
    type: types.LOG_OUT,
  };
}
export function create_payments_wildcore(payload) {
  return {
    type: types.CREATE_PAYMENT_WILDCORE,
    payload
  };
}
export function get_payments_wildcore(payload) {
  return {
    type: types.GET_PAYMENT_WILDCORE,
    payload
  };
}
export function approve_payments_wildcore(payload) {
  return {
    type: types.APPROVE_PAYMENT_WILDCORE,
    payload
  };
}
export function delete_payments_wildcore(payload) {
  return {
    type: types.DELETE_PAYMENT_WILDCORE,
    payload
  };
}
export function create_payments_withdraw(payload) {
  return {
    type: types.CREATE_PAYMENT_WITHDRAW,
    payload
  };
}
export function get_payments_withdraw(payload) {
  return {
    type: types.GET_PAYMENT_WITHDRAW,
    payload
  };
}
export function approve_payments_withdraw(payload) {
  return {
    type: types.APPROVE_PAYMENT_WITHDRAW,
    payload
  };
}
export function delete_payments_withdraw(payload) {
  return {
    type: types.DELETE_PAYMENT_WITHDRAW,
    payload
  };
}
export function register_ctv(payload) {
  return {
    type: types.REGISTER_CTV,
    payload,
  };
}
export function reset_user(payload) {
  return {
    type: types.RESET_USER,
    payload,
  };
}
export function update_user(payload) {
  return {
    type: types.UPDATE_USER,
    payload,
  };
}
export function get_users(payload) {
  return {
    type: types.GET_USER,
    payload,
  };
}
export function set_me(payload) {
  return {
    type: types.SET_ME,
    payload,
  };
}
export function change_password(payload) {
  return {
    type: types.CHANGE_PASSWORD,
    payload,
  };
}